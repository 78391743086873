import React from 'react';
import ReactDOM from 'react-dom';
import "svm-dashboard/dist/assets/css/Dashboard.css";
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import 'tfo-groove/dist/assets/css/groove.css'
import 'bootstrap/dist/css/bootstrap.css';
import './CSS/common.css';
import './CSS/custom.css';
import './CSS/theme.css';
export const HostContext = React.createContext();
// import { runWithAdal } from 'react-adal';
// import { authContext } from './Config/AdalConfig';
import { MsalProvider,MsalAuthenticationTemplate } from "@azure/msal-react";
import { PublicClientApplication, EventType,InteractionType } from "@azure/msal-browser";
import { msalConfig,loginRequest } from './Config/AdalConfig';

// const DO_NOT_LOGIN = false;


// runWithAdal(authContext, () => {
//     require('./indexApp.js');

// }, DO_NOT_LOGIN);

export const msalInstance = new PublicClientApplication(msalConfig);

// Default to using the first account if no account is active on page load
if (!msalInstance.getActiveAccount() && msalInstance.getAllAccounts().length > 0) {
    // Account selection logic is app dependent. Adjust as needed for different use cases.
    msalInstance.setActiveAccount(msalInstance.getAllAccounts()[0]);
  }

  // Optional - This will update account state if a user signs in from another tab or window
msalInstance.enableAccountStorageEvents();
  

msalInstance.addEventCallback((event) => {
  if (event.eventType === EventType.LOGIN_SUCCESS && event.payload.account) {
    const account = event.payload.account;
    msalInstance.setActiveAccount(account);
  }
});

//Uncomment ReactDOM.render() to run in LOCAL
// ReactDOM.render(
//     <React.StrictMode>
//         <App />
//     </React.StrictMode>,
//     document.getElementById('root')
// );


//Comment ReactDOM.render() to run in LOCAL
ReactDOM.render(
    <React.StrictMode>
        <MsalProvider instance={msalInstance}>
        <MsalAuthenticationTemplate
            interactionType={InteractionType.Redirect}
            authenticationRequest={loginRequest}
        > 
        <App />
        </MsalAuthenticationTemplate>
        </MsalProvider>
    </React.StrictMode>,
    document.getElementById('root')
);

reportWebVitals();