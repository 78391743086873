// import { AuthenticationContext, adalFetch, withAdalLogin } from 'react-adal';
// import * as Constants from '../Common';

 
//  export const adalConfig  = {
//    // To run in local uncomment this part of code

//     // tenant: "f3211d0e-125b-42c3-86db-322b19a65a22",
//     // clientId: "40c9e650-5005-4019-b96b-b685850ec403",
//     // redirectUri:  "https://clientcockpitdev.accenture.com",
//     // postLogoutRedirectUri: "https://clientcockpitdev.accenture.com/logout",
//     // endpoints: {
//     //     api:"https://login.microsoftonline.com/f3211d0e-125b-42c3-86db-322b19a65a22/oauth2/authorize"
   
//     // To run in dev/uat.prod uncomment this part of code
//     tenant : process.env.REACT_APP_ADAL_CONFIG_TENANT,
//     clientId: process.env.REACT_APP_ADAL_CONFIG_CLIENTID,
//     baseurl: process.env.REACT_APP_ADAL_CONFIG_BASEURL,
//     redirectUri: process.env.REACT_APP_ADAL_CONFIG_REDIRECTURI,
//     postLogoutRedirectUri: process.env.REACT_APP_ADAL_CONFIG_POSTLOGOUTREDIRECTURI,
//     endpoints:{
//           api: process.env.REACT_APP_ADAL_CONFIG_ADALAPI
//     },
//  // };

//   // REMOVE SOME PROPERTIES AS THEY ARE NOT NEEDED FOR PRODUCTION ENVIRONMENT
//   // if (process.env.NODE_ENV === 'production') {
//   // _.unset(configObj, 'baseurl');
//   // // _.unset(configObj, 'redirect_uri');
//   // }

//   // export const adalConfig = {
//   //   ...configObj,
    
//     /*Dev deploy */

//   // tenant: 'f3211d0e-125b-42c3-86db-322b19a65a22',//Dev
//   // clientId: '40c9e650-5005-4019-b96b-b685850ec403',//Dev
//   // resource:'https://clientcockpitapidev.accenture.com/Clientcockpit_API/api/',
//   // redirect_uri:'https://clientcockpitdev.accenture.com',
//   // endpoints: {
//   //     api: 'https://login.microsoftonline.com/f3211d0e-125b-42c3-86db-322b19a65a22/oauth2/authorize' // <--Dev
//   // },


//     expireOffsetSeconds: 1000,
//     loadFrameTimeout: 500,
//     navigateToLoginRequestUrl: true,
//     cacheLocation: 'sessionStorage',
// };
 
// MSAL Implementation
// var msalConfig = {
//   auth: {
//     api: process.env.REACT_APP_ADAL_CONFIG_ADALAPI,
//     clientId: process.env.REACT_APP_ADAL_CONFIG_CLIENTID,
//     authority: 'https://login.microsoftonline.com/accenture.onmicrosoft.com',
//     redirectUri: process.env.REACT_APP_ADAL_CONFIG_REDIRECTURI, 
//     cacheLocation: 'sessionStorage',
//     storeAuthStateInCookie: true,
//     logout: 'https://login.microsoftonline.com/'+ process.env.REACT_APP_ADAL_CONFIG_CLIENTID+'/oauth2/logout',
//     scopes: [ process.env.REACT_APP_ADAL_CONFIG_CLIENTID+'/read_write_cvm']
//     }
//   }
// var msalConfig = {

//   auth: {
//       clientId: process.env.REACT_APP_ADAL_CONFIG_CLIENTID,
//       authority: 'https://login.microsoftonline.com/'+ process.env.REACT_APP_ADAL_CONFIG_TENANT,
//       redirectUri: process.env.REACT_APP_ADAL_CONFIG_REDIRECTURI,
//       postLogoutRedirectUri: process.env.REACT_APP_ADAL_CONFIG_POSTLOGOUTREDIRECTURI
//   },
//   cache: {
//       cacheLocation: 'sessionStorage',
//   },
// };

export const msalConfig = {
  auth: {
      clientId: process.env.REACT_APP_ADAL_CONFIG_CLIENTID,
      authority:'https://login.microsoftonline.com/'+ process.env.REACT_APP_ADAL_CONFIG_TENANT,
      redirectUri: process.env.REACT_APP_ADAL_CONFIG_REDIRECTURI,
      postLogoutRedirectUri: process.env.REACT_APP_ADAL_CONFIG_POSTLOGOUTREDIRECTURI,
      logout: 'https://login.microsoftonline.com/'+ process.env.REACT_APP_ADAL_CONFIG_CLIENTID+'/oauth2/logout'
  },
  cache: {
      cacheLocation:'sessionStorage'
  }
};
export const loginRequest = {
  scopes:[ process.env.REACT_APP_ADAL_CONFIG_CLIENTID+'/user_impersonation'],
  extraScopesToConsent: ["https://analysis.windows.net/powerbi/api/Report.Read.All"]
};

export const loginRequestPowerBi = {
  scopes: ["https://analysis.windows.net/powerbi/api/Report.Read.All"]
}

export const profilePicloginRequest = {
  scopes:[ process.env.REACT_APP_HUMAN_API_SCOPE ] 
};

export const graphConfig = {
  graphMeEndpoint: "https://graph.microsoft-ppe.com/v1.0/me"
};
// export const authContext = new AuthenticationContext(adalConfig);
 
// export const adalApiFetch = (fetch, url, options) =>
//   adalFetch(authContext, adalConfig.endpoints.api, fetch, url, options);
 
// export const withAdalLoginApi = withAdalLogin(authContext, adalConfig.endpoints.api);

// export {msalConfig};
 
