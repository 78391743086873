import axios from 'axios';
import promise from 'promise';
import { loginRequest } from '../Config/AdalConfig';
import { msalInstance } from "../index";
import { InteractionRequiredAuthError } from "@azure/msal-browser";

// import { adalConfig, authContext } from '../Config/AdalConfig';

var axiosInstance = axios.create();

// Comment this axiosInstance to run in Local
axiosInstance.interceptors.request.use(async function(config) {
    const account = msalInstance.getActiveAccount();
    if (!account) {
        throw Error("No active account! Verify a user has been signed in and setActiveAccount has been called.");
    }
    const response = await msalInstance.acquireTokenSilent({
        ...loginRequest,
        account: account
    }).catch(err => {
        //as a fallback so that user does not go away from the page when acquiretokensilent fails, use acquireTokenPopup method
        //always check if the error is something that requires auth login interaction 
        if (err instanceof InteractionRequiredAuthError) {
            return msalInstance.acquireTokenPopup({
                ...loginRequest,
                account: msalInstance.getActiveAccount()
            }).catch(err => {
                console.log('Error getting popup token: ' + JSON.stringify(err))
            });
        }
    });
    // const profilePicResponse = await msalInstance
    //   .acquireTokenSilent({
    //     ...profilePicloginRequest,
    //     account: account,
    //   })
    //   .catch((err) => {
    //     console.log('Error getting popup token: ' + JSON.stringify(err));
    //     sessionStorage.setItem("profilePicToken",response.accessToken);
    //   });
    sessionStorage.setItem("accessToken",response.accessToken);
    // sessionStorage.setItem("profilePicToken",profilePicResponse.accessToken);
    config.headers.Authorization=`Bearer ${response.accessToken}`;
    
    
    return config;
}, function(error) {
    return promise.reject(error);
});


//Uncomment this axiosInstance to run in Local
// axiosInstance.interceptors.request.use(async function(config) {

//     var token = sessionStorage.getItem("accessToken");
//     config.headers.Authorization=`Bearer ${token}`;    
    
//     return config;
// }, function(error) {
//     return promise.reject(error);
// });

export default axiosInstance;