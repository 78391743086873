/* eslint-disable */
import React, { useState } from 'react';
import LoadingOverlay from 'react-loading-overlay';

 export function Preloader() {
    const [theme,setTheme] = useState(sessionStorage.getItem("theme"));
    return (

          <div className={theme === "1" ? "preloader-animation DT_preloder_overlay ": "preloader-animation "}>
              
        <LoadingOverlay

            classNamePrefix="_preloader_overlay"

            active={true}

            spinner

            text={"Loading Data" + " …"}

        >

        </LoadingOverlay>

        </div>

    );

  }

